'use client';
import { type IconType } from '@/app/_ui/types';
import clsx from 'clsx';
import {
  isValidElement,
  useEffect,
  useRef,
  useState,
  type ChangeEvent,
  type ComponentType,
  type HTMLInputTypeAttribute,
  type KeyboardEvent,
  type ReactElement,
  type SVGProps,
} from 'react';

export type TextInputProps = {
  name: string;
  value?: string | number;
  type?: HTMLInputTypeAttribute;
  label?: string;
  labelPlacement?: 'top' | 'left';
  description?: string;
  error?: boolean;
  message?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  border?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  variant?: 'primary' | 'outlined' | 'plain';
  Icon?: IconType | ReactElement;
  iconPlacement?: 'left' | 'right';
  autofocus?: boolean;
  compact?: boolean;
  hideLabel?: boolean;
  hidePlaceholder?: boolean;
  placeholder?: string;
  className?: string;
  inputProps?: Record<string, any>;
  required?: boolean;
};

const TextInput = ({
  name,
  label,
  description,
  error,
  message,
  onChange,
  onBlur,
  onKeyDown,
  Icon,
  placeholder,
  className,
  inputProps,
  value = '',
  labelPlacement = 'top',
  type = 'text',
  border = true,
  disabled = false,
  fullWidth = true,
  variant = 'primary',
  iconPlacement = 'left',
  autofocus = false,
  compact = false,
  hideLabel = false,
  hidePlaceholder = false,
  required = false,
}: TextInputProps) => {
  const [visited, setVisited] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autofocus && inputRef?.current != null && !disabled) {
      inputRef.current.focus();
    }
  }, [autofocus, disabled]);

  const isDisabled = disabled || !onChange;
  const inputClass = clsx(
    'shadow-sm appearance-none rounded-md w-full py-2 px-3 text-white leading-tight transition-all duration-200 ease-in-out',
    'focus:outline-none focus:ring-2 focus:ring-purple-400 focus:border-transparent',
    fullWidth ? 'w-full' : 'w-fit',
    border && 'border border-gray-500',
    variant === 'primary' && 'bg-gray-600',
    variant === 'outlined' && 'bg-transparent border-2 border-purple-400',
    variant === 'plain' && 'bg-transparent',
    isDisabled && 'opacity-50 cursor-not-allowed',
    compact ? 'text-sm py-1' : 'text-base',
    visited && error && 'border-red-200 focus:ring-red-200',
    Icon != null && (iconPlacement === 'left' ? 'pl-10' : 'pr-10'),
    'placeholder-gray-300',
    isFocused ? 'ring-2 ring-purple-400' : 'bg-opacity-80',
  );
  const labelClass = clsx(
    'block text-sm font-medium mb-1 transition-all duration-200 ease-in-out placeholder-gray-200 placeholder-opacity-100',
    isFocused ? (error ? 'text-red-200' : 'text-purple-400') : 'text-gray-50',
    isDisabled && 'caret-transparent',
  );
  const descriptionClass = clsx('text-xs text-gray-200 mb-2');
  const messageClass = clsx(
    'text-sm mt-1',
    error ? 'text-red-200' : 'text-gray-100',
  );

  const handleFieldFocus = () => {
    setVisited(true);
    setIsFocused(true);
  };

  const handleFieldBlur = (e: ChangeEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (onBlur) {
      onBlur(e);
    }
  };

  const isIconComponent = (
    icon: IconType | ReactElement,
  ): icon is ComponentType<SVGProps<SVGSVGElement>> =>
    typeof icon === 'function';

  return (
    <div className={clsx('relative mb-4', fullWidth && 'flex-1', className)}>
      {label && !hideLabel && (
        <label className={labelClass} htmlFor={name}>
          {label} {required && <span>*</span>}
        </label>
      )}
      {description && <p className={descriptionClass}>{description}</p>}

      {Icon != null && (
        <span
          className={clsx(
            // do not set it to inset-y because it won't render properly with error messages
            'absolute top-2 flex items-center text-gray-300',
            iconPlacement === 'left' ? 'left-3' : 'right-3',
            label && !hideLabel && 'pt-6',
          )}
        >
          {isValidElement(Icon) ? (
            Icon
          ) : isIconComponent(Icon) ? (
            <Icon className="h-6 w-6" />
          ) : null}
        </span>
      )}
      <input
        ref={inputRef}
        onFocus={handleFieldFocus}
        onChange={onChange}
        onBlur={handleFieldBlur}
        onKeyDown={onKeyDown}
        className={inputClass}
        name={name}
        type={type}
        value={value}
        placeholder={!hidePlaceholder ? placeholder ?? label : undefined}
        disabled={isDisabled}
        {...inputProps}
      />

      {message && <p className={messageClass}>{message}</p>}
    </div>
  );
};

export default TextInput;
